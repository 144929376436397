.dropdown {
    position: relative;
    display: inline-block;

    &.on-rigth .dropdown-content {
        left: 0;
    }

    &.on-left .dropdown-content {
        right: 0;
    }

    &.is-active .dropdown-content {
        transform: scale(1, 1) translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

.dropdown-content {
    transition: 0.3s;
    visibility: collapse;
    opacity: 0;
    position: absolute;
    background-color: $secondary-bg;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: $radius-sm;
    border: solid 1px $border-color;

    hr {
        margin: 0;
    }

    a {
        color: $color;
        padding: 10px 14px;
        text-decoration: none;
        display: block;

        &:hover {
            background-color: $border-color
        }
    }

}