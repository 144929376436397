@mixin input($bg: #f2f2f2) {
	padding: 8px 16px;
	margin: 8px 0;
	display: inline-block;
	background-color: $bg;
	border: 1px solid $bg;
	border-radius: 0.2rem;
	width: 100%;
	&::placeholder {
		opacity: 75%;
	}
	&:focus {
		outline: none;
		border-bottom: 1px solid $primary-color;
	}
}

@mixin text-input($bg, $color) {
    width: 100%;
    padding: 8px 12px;
    border-radius: $radius-sm;
    outline: none;
    border: none;
    background-color: $bg;
    color: $color;
    border-bottom: 2px solid $bg;
    &:focus {
        border-bottom: 2px solid $primary-color;
    }
}

@mixin placeholder($background, $color) {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: $color;
        box-shadow: 0 0 0px 1000px $background inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

@mixin checkbox($bg) {
    display: block;
    position: relative;
    padding-left: 1.4rem;
    cursor: pointer;
    font-size: inherit;
    user-select: none;
    margin-top: .5rem;
    margin-bottom: .5rem;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    // &:hover input ~ .checkmark {
    //     background-color: #ccc;
    // }
    & input:checked ~ .checkbox {
        background-color: $bg;
    }
    & input:checked ~ .checkbox:after {
        display: block;
    }

    .checkbox:after {
        left: 6px;
        top: 2.7px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .checkbox {
        position: absolute;
        top: 0;
        left: 0;
        height: 1em;
        width: 1em;
        background-color: #ccc;
        border-radius: $radius-sm;
    }
    
    .checkbox:after {
        content: "";
        position: absolute;
        display: none;
    }
}