.chips {
    text-indent: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0.8rem 0;

    li {
        font-size: 0.8rem;
        display: inline-block;
        background-color: $secondary-bg;
        border-radius: $radius-full;
        padding: 0.4rem 0.6rem;
        margin-right: 0.4rem;
    }

}

.chip {

    &-close {
        margin-left: 0.3rem;
    }
}