.collapse {
    &.has-style {
        background-color: $secondary-bg;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        transition: 0.3s;
        border-radius: $radius-sm;

        &.is-active,
        &:hover {
            background-color: $secondary-color;
            color: #fff;
        }
    }
}

.collapse-group {
    .collapse {
        &:first-child {
            border-radius: rpt(2, $radius-sm) 0 0rem;
        }
        
        &:nth-last-child(2) {
            border-radius: 0 0 rpt(2, $radius-sm);
        }
    }
}

.collapse-content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: $bg;
}