// botones
.button {
    @include main-btn;

    &.is-fullwidth {
        display: block;
        width: 100%;
    }
    &.is-small {
        padding: 0.2rem 0.5rem;
	    font-size: 0.8rem;
    }
    &.is-normal {
        padding: 0.5rem 0.7rem;
	    font-size: 1rem;
    }
    &.is-large {
        padding: 0.6rem 1.2rem;
	    font-size: 1.4rem !important;
    }
    &.is-rounded {
        border-radius: 5rem
    }
    // colores y outline
    @each $name, $color in $colors {
        &.is-#{$name} {
            @include btn-item($color);
            &.is-outlined {
                background: transparent;
                color: $color;
                &:hover {
                    background: $color;
                    color: #fff;
                }
            }
        }
    }
    &[disabled], &:disabled {
        cursor: not-allowed;
        opacity: 50%;
    }
}