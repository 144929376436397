@import url(https://fonts.googleapis.com/icon?family=Material+Icons+Round);
[data-icon] {
    font-family: "Material Icons Round";
    font-weight: 400;
    font-style: normal;
    font-size: 0.9em;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px);
}
[data-icon]::after {
    content: attr(data-icon);
}
span[data-icon] {
    font-size: 0.9em;
}
i[data-icon] {
    font-size: 1em;
}