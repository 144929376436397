@mixin container {
    padding-left: 1rem;
    padding-right: 1rem;
    
    @include from(md) {
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: 1000px;
        margin: auto;
    }
}
