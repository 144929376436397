.trackpage {

    max-width: 650px;
    margin: auto;

    &-topbuttons {
        margin-bottom: 1rem;
        display: grid;
        @include col(1 1 1);

        .button-rigth {
            text-align: end;
        }

        button,
        a {
            font-size: 0.8rem;
        }
    }

    &-iframe {
        height: 250px;
        width: 100%;
        margin-bottom: 0.7rem;
    }

    &-genres {
        opacity: 0.6;
        font-size: 0.8rem;
    }

    &-title {
        margin-top: 0.3rem;
        line-height: 0.9rem;
    }

    hr {
        margin-top: 0.3rem;
        margin-bottom: 0.5rem;
    }

    &-date {
        font-size: 0.8rem;
        opacity: 0.6;
        margin-bottom: 0.5rem;

        img {
            border-radius: 3px;
            display: inline-block;
        }
    }

    &-artist {
        margin: 0;
    }

    &-description {
        p:last-child {
            margin: 0;
        }

        opacity: 0.6;

        a {
            color: $primary-color;
        }
    }
}

.global-iframe {
    z-index: 20;
    background-color: $secondary-bg;

    &-top {
        border-radius: $radius-sm;
        height: 250px;
        width: 100%;
        top: 4.3rem;
        left: 50%;
        transform: translate(-50%, 0);
        position: absolute;
        max-width: 650px;

        .close-button {
            display: none;
        }

        .layer {
            display: none;
        }
    }

    &-bottom {
        border-radius: $radius-sm;
        position: fixed;
        bottom: 85px;
        right: 15px;
        width: 320px;
        height: 180px;

        @include to(sm) {
            width: 100%;
            right: 0;
            height: 150px;
            bottom: 70px;
            border-radius: 0;
        }

        .close-button {
            display: none;
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            cursor: pointer;
            background-color: $secondary-bg;
            border: 2px solid $extra-bg;
            padding: 0 0.3rem;
            border-radius: $radius-full;
            font-size: 0.9rem;
            z-index: 30;
        }

        &:hover .close-button {
            display: inline-block;
        }

        .layer {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 25;
        }
    }

    iframe {
        border-radius: $radius-sm;
        width: 100%;
        height: 100%;

        @include to(sm) {
            border-radius: 0;
        }
    }

}