.modal {
    position: fixed;
    background-color: rgba(#000, 0.8);
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    &-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &-content {
        background-color: $secondary-bg;
        color: $color;
        padding: $padding-lg;
        border-radius: $radius-sm;
    }

    &.is-close {
        visibility: hidden;
        opacity: 0;
    }
    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}