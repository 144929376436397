.track {
    background-color: $secondary-bg;
    padding: $padding;
    border-radius: $radius-sm;
    display: grid;
    grid-template-columns: 5rem 1fr;
    gap: 1rem;
    border: 1px solid transparent;

    &-data {
        @include center-y;
    }

    &-icon {
        @include center;
        background-color: $bg;
        border-radius: $radius-sm;
    }

    &-title {
        font-size: 1.1rem;
        margin: 0;
    }

    &-artist {
        margin: 0;
        opacity: 0.6;
        font-size: 0.8rem;
    }

    &-genres {
        opacity: 0.6;
        font-size: 0.8rem;

        img {
            border-radius: 3px;
            display: inline-block;
        }
    }

    &.is-listened {
        background-color: $bg;
        border: 1px solid $extra-bg;
        // border-left: 1px solid $extra-bg;
        // border-right: 1px solid $extra-bg;
        // border-bottom: 1px solid $secondary-color;
    }

    &-actionbuttons {
        margin-top: 0.3rem;
        button {
            font-size: 0.7rem !important;
            @include to(sm) {
                width: 100% !important;
            }
        }
    }
}

.new-track {
    background-color: $secondary-bg;
    padding: $padding;
    border-radius: $radius-sm;
    cursor: pointer;
    text-align: center;
}